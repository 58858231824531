window.esign = window.esign || {};

esign.cacheSelectors = function () {
  esign.cache = {
    // general
    $html: $('html'),

    // navigation
    $nav: $('.main-nav__wrap')

  };
};

esign.init = function () {

  Response.create({
    prop: "width",
    prefix: "min-width- r src",
    breakpoints: [992,0],
    lazy: true
  });

  esign.gaDevelopment();
  esign.cacheSelectors();

  esign.navigation();
  esign.responsiveVideos();
  esign.formAjax();
  //esign.initVideo();
  //esign.initSlider();
  //esign.initShuffle();
  //esign.initFilters();
  //esign.initFancybox();
  esign.scrollOnPageLinks();
  esign.initSideNavScroll();
  esign.initAsyncContentLoading();
  esign.initCookie();
  esign.initTextAnimation();
  esign.visibilityTrigger();
  esign.perfectScale();
  esign.domainsSection();
  setTimeout(() => {
      esign.hubspotFormCheckCheckbox();
  }, 1500);

};

esign.scrollOnPageLinks = function() {
  $('a[href*=\\#]:not(.no-scroll-link)').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
      && location.hostname == this.hostname) {
      var $target = $(this.hash);
      if(this.hash.slice(1)) {
        $target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
        if ($target.length) {
          $('html, body').animate({
            scrollTop: $target.offset().top - $('.page-header').height() - 20
          }, 400);
          return false;
        }
      } else {
        $('html, body').animate({
          scrollTop: 0
        }, 400);
      }
    };
  });

  if(location.hash.length) {
    $('a[href="' + location.hash + '"]').trigger('click');
  }
};

esign.triggerMenuSlide = function() {
  var leftValue = parseInt($('.main-nav__wrap').css('left')) === 0 ? '100%' : 0;
  if(!leftValue) {
    $('.main-nav__trigger').addClass('open')
  } else {
    $('.main-nav__trigger').removeClass('open');
  }
  $('.main-nav__trigger').next('.main-nav__wrap').css({'left': leftValue});
};

esign.navigation = function () {
  $('.main-nav__trigger').click(function(e) {
    e.preventDefault();
    esign.triggerMenuSlide();
  });

  $('.dropdown-toggle').click(function(e) {
    e.preventDefault();
    if($(this).parent().hasClass('show')) {
      $(this).parent().removeClass('show');
    } else {
      $(this).parent().addClass('show');
    }
  });

  $('.collapse-trigger').click(function(e) {
    if($(window).width() >= 992) {
      return;
    }

    var collapseToggle = $(this).parent();
    var ul = collapseToggle.parent().find('ul').first();

    e.preventDefault();
    var collapsed = collapseToggle.attr('aria-expanded');
    if(collapsed === 'false') {
      ul.addClass('in');
      var ulHeight = ul.find('li').length * 18;
      ul.css({height: ulHeight + 'px'});
      collapseToggle.attr('aria-expanded', true);
      collapseToggle.find('span').removeClass('icon-plus').addClass('icon-minus');
    } else{
      ul.removeClass('in');
      ul.css({height: 0});
      collapseToggle.attr('aria-expanded', false);
      collapseToggle.find('span').removeClass('icon-minus').addClass('icon-plus');
    }
  });
};

esign.responsiveVideos = function () {
  $('iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]').wrap('<div class="video-container"></div>');
};

// Ajax newsletter subscribe
esign.formAjax = function () {
  $('.form-ajax').submit(function(e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr("disabled", "disabled");

    $.post($form.attr('action'), $form.serializeArray(), function(data) {
      if(data.errors === false) {
        $form.html(data.result);
      } else {
        $form.find('.result').html(data.result);
        $form.find('button, input[type="submit"]').removeAttr('disabled');
      }
    });

    e.preventDefault();
    return false;
  });
};

// Log ga calls in development
esign.gaDevelopment = function() {
  if (typeof ga === typeof undefined) {
    window.ga = function() {
      var argumentsArray = [];
      for (var key in arguments) {
        if (arguments.hasOwnProperty(key)) {
          argumentsArray.push(arguments[key]);
        }
      }

      var msg = '[GA DEV] Call with arguments [' + argumentsArray.join(',') + ']';
      console.log('%c' + msg, 'background: #ff9800; color: #fff;');
    };
  }
};

esign.initVideo = function() {
  $('.video-play-button').click(function(e) {
    e.preventDefault();
    $video = $(this).parent().find('.video');
    $(this).parent().addClass('hide-overlay');
    $video.attr('controls', '');
    $video.get(0).play();
  });

  $('.video').on('pause', function() {
    $(this).parent().removeClass('hide-overlay');
    $(this).removeAttr('controls');
  });
};

esign.initSlider = function() {
  $('.slider').slick({
    'arrows': false,
    'dots': true
  });
};

esign.onScroll = function() {
  var scrollTop = $(document).scrollTop();
  if(scrollTop === 0) {
    $('.page-header__logo').removeClass('scroll');
  } else {
    $('.page-header__logo').addClass('scroll');
  }
};

var shuffleInstance;
esign.initShuffle = function() {
  var Shuffle = window.Shuffle;
  var element = document.querySelector('.shuffle-container');

  if(element) {

    shuffleInstance = new Shuffle(element, {
      itemSelector: '.shuffle-item',
      filterMode: Shuffle.FilterMode.ALL
    });

    shuffleInstance.filter('');
  }
};

esign.initFilters = function() {
  if($('body').hasClass('home')) {
    $('body').click(function () {
      $('.filter').removeClass('active');
    })
    $('.filter').click(esign.onFilterClick);

    $('.filter-select').click(esign.onFilterSelectClick);
  }
};

esign.onFilterClick = function(e) {
  e.stopPropagation();
  if($(this).hasClass('active')) {
    $(this).removeClass('active');
  } else {
    $('.filter').removeClass('active');
    $(this).addClass('active');
  }
};

var filterArray = ['', '', '', ''];
esign.onFilterSelectClick = function(e) {
  e.stopPropagation();
  if(shuffleInstance) {
    var text = $(this).html();
    if(text.toLowerCase() === 'show all') {
      text = '';
    }
    $('.filter-select').removeClass('active');
    $(this).addClass('active');
    $('.filter').removeClass('active');
    var $filterName =  $(this).closest('.filter').find('.filter-name');

    filterArray[$filterName.data('filter-nr')] = text;
    shuffleInstance.filter(filterArray);

    if(text.length > 0 ) {
      $filterName.html(text).addClass('selection');
    } else {
      $filterName.html($filterName.data('name')).removeClass('selection');
    }

    if($('.shuffle-item').length === $('.shuffle-item--hidden').length) {
      $('.no-items-available').addClass('show');
    } else {
      $('.no-items-available').removeClass('show');
    }
  }
};

$(window).scroll(esign.onScroll);
esign.onScroll();

esign.initFancybox = function() {
  fancyboxOptions =
    {
      'padding': 0,
      tpl: {
        closeBtn: '<a title="Close" class="fancybox-item fancybox-close custom-fancybox-close" href="javascript:;"><i class="icon-close"></i> </a>'
      },
    };

  $('.fancybox-link').fancybox(fancyboxOptions);
};

var originalSideNavScrollTop;
esign.initSideNavScroll = function() {
  if($('.side-nav-scroll').length) {
    originalSideNavScrollTop = $('.side-nav-scroll').offset().top;
    $(window).scroll(function() {
      esign._checkSideNavScroll();
    });
  }
};

esign._checkSideNavScroll = function() {
  var $sideNavScroll = $('.side-nav-scroll');
  if($(window).width() >= 992) {

    var scrollTop = $(window).scrollTop() + 82;

    if(scrollTop > originalSideNavScrollTop - 30) {
        $sideNavScroll.addClass('fixed');
        if(scrollTop + $sideNavScroll.height() + 100 >= $('.main').height()) {
          $sideNavScroll.addClass('fade-out')
        } else {
          $sideNavScroll.removeClass('fade-out');
        }
    } else {
      $sideNavScroll.removeClass('fixed');
    }
  } else {
    //$sideNavScroll.css({top: 0});
    $sideNavScroll.removeClass('fixed');
  }
};

esign.initAsyncContentLoading = function() {
  $('#btn-more-news').click(function(e) {
    esign._asyncLoadContent(e, $('#btn-more-news'), esign._fillInNewsTemplate);
  });
  $('#btn-more-experts').click(function(e) {
    esign._asyncLoadContent(e, $('#btn-more-experts'), esign._loadMoreExperts);
  });
};

esign._asyncLoadContent = function(e, $button, onSuccessFunction){
  var buttonOriginalHtml;

  function onSuccess (jsonData) {
    try {
      onSuccessFunction(jsonData.data);
    }
    catch(err) {
      onError(err);
    }
    if(jsonData.meta.pagination.links.next && jsonData.meta.pagination.links.next.length) {
      $button.data('fetch-url', jsonData.meta.pagination.links.next);
      $button.blur();
      $button.removeClass('button-loading');
      $button.html(buttonOriginalHtml);
    } else {
      $button.remove();
    }
  }

  function onError(err) {
    $button.blur();
    $button.removeClass('button-loading');
    $button.html(buttonOriginalHtml);
    console.error('could not load content ');
    if(err) {
      console.log(err);
    }
  }

  buttonOriginalHtml = $button.html();
  $button.css({width: $button.outerWidth()});
  $button.html('Loading');
  $button.addClass('button-loading');

  $.ajax({
    dataType: "json",
    url: $button.data('fetch-url'),
    data: {},
    success: onSuccess,
    error: onError
  });

  e.preventDefault();
};


esign._loadMoreExperts = function(jsonDataExperts) {
  var $gridItem = $('.expert-grid-item').first();
  var $insertBeforeEl = $('#insert-experts-before');

  console.log(jsonDataExperts);
  for (var i = 0; i < jsonDataExperts.length; i++) {
    var $newEl = $gridItem.clone();
    $newEl.find('.expert-photo').css({'background-image': 'url(' + jsonDataExperts[i].image + ')'});
    $newEl.find('h3').html(jsonDataExperts[i].firstname + ' ' + jsonDataExperts[i].lastname);

    var $list = $newEl.find('ul');
    $list.html('');
    for(var j = 0; j < jsonDataExperts[i].articles.length; j++) {
      $newListItem = $("<li><a><span></span></a></li>");
      $list.append($newListItem);
      $newListItem.find('a').attr('href', jsonDataExperts[i].articles[j].slug);
      $newListItem.find('a').attr('title', jsonDataExperts[i].articles[j].title);
      $newListItem.find('span').html(jsonDataExperts[i].articles[j].title);
    }

    $newEl.insertBefore($insertBeforeEl);
  }

};

esign._fillInNewsTemplate = function(jsonDataNews) {
  var $template;
  if(jsonDataNews.length > 6) {
    var $template1 = $('.template4-left').first();
    var $template2 = $('.template4-right').first();
    $template = $($template1.add($template2));
  } else if(jsonDataNews.length > 4) {
    var $template1 = $('.template4-left').first();
    var $template2 = $('.template3').first();
    $template = $($template1.add($template2));
  } else if(jsonDataNews.length === 4) {
    $template = $('.template4-left').first();
  } else {
    $template = $('.template3').first();
  }

  var $insertBeforeEl = $('#insert-news-before');
  var $newEl = $template.clone();
  $newEl.removeClass('blog-grid-items-template');
  $newEl.insertBefore($insertBeforeEl);

  var blogItems = $newEl.find('.blog-item');
  for(var i = 0; i < blogItems.length; i++ ) {
    var $blogItem = $(blogItems[i]);
    if( i < jsonDataNews.length) {
      $blogItem.find('.blog-item__thumb').css({'background-image': 'url(' + jsonDataNews[i].articleImageThumbnail + ')'});
      $blogItem.find('a').attr('href', jsonDataNews[i].url);
      $blogItem.find('.blog-item__text-wrap h2').html(jsonDataNews[i].title);
      $blogItem.find('.blog-item__text-wrap p').html(jsonDataNews[i].articleDescription);
      if(jsonDataNews[i].articleExternalUrl.length) {
        $blogItem.find('.plus-sign:not(.plus-sign--ext)').remove();
        $blogItem.find('a').attr('target', '_blank');
      } else {
        $blogItem.find('.plus-sign--ext').remove();
      }
      $blogItem.parent().removeClass('js-animated');
    } else {
      $blogItem.parent().remove();
    }
  }

  // trigger window scroll for animations
  $(window).scroll();
};

esign.initCookie = function() {
  var $cookie = $('.cookie');

  var _updateCookieHeight = function() {
    var cookieHeight = $cookie.height();
    $('body').css({'padding-top': cookieHeight});
    $('.page-header').css({'top': cookieHeight});
    console.log('calling update Cookie height');
  };

  $(window).resize(_updateCookieHeight);

  if(Cookies.get('cookie-consent') === undefined) {
    $cookie.show();
    _updateCookieHeight();
  } else {
    $(window).off('resize', _updateCookieHeight);
  }

  $('.cookie .close').click(function() {
    Cookies.set('cookie-consent', 'true', { expires: 355 });
    $cookie.hide();
    $(window).off('resize', _updateCookieHeight);
    $('.page-header').css({'top': 0});
    $('body').css({'padding-top': 0});
  });
};

esign.initTextAnimation = function() {
  $('.text-animation').each(function() {
    var $slides = $(this).find('.text-animation__slide');
    var $togglePrevious = $(this).find('.text-animation__toggle[data-action="previous"]');
    var $toggleNext = $(this).find('.text-animation__toggle[data-action="next"]');
    var currentSlide = 0;
    var changeSlideTimeout = null;

    var _previousSlide = function() {
      clearTimeout(changeSlideTimeout);
      $($slides[currentSlide]).addClass('hide');
      $($slides[currentSlide]).find('> *').removeClass('show');
      currentSlide -= 1;
      $($slides[currentSlide]).removeClass('hide');
      setTimeout(function() {
        $($slides[currentSlide]).find('> *').addClass('show');
      });

      $toggleNext.removeClass('disabled');
      _resetTimer();
      if(currentSlide === 0) {
        $togglePrevious.addClass('disabled');
      }
    };

    var _nextSlide = function() {
      clearTimeout(changeSlideTimeout);
      $($slides[currentSlide]).addClass('hide');
      setTimeout(function() {
        $($slides[currentSlide]).find('> *').removeClass('show');
      }, 100);
      currentSlide += 1;
      $($slides[currentSlide]).removeClass('hide');
      setTimeout(function() {
        var delay = 0;
        $($slides[currentSlide]).find('> *').each(function() {
          $(this).css({'transition-delay': delay + 'ms'});
          $(this).addClass('show');
          delay += 1200;
        });
      }, 200);

      $togglePrevious.removeClass('disabled');
      if(currentSlide === $slides.length - 1) {
        $toggleNext.addClass('disabled');
      } else {
        _resetTimer();
      }
    };

    var _resetTimer = function() {
      var $circle = $toggleNext.find('circle');
      $circle.removeClass('countdown-animation');
      setTimeout(function() {
        $circle.addClass('countdown-animation');
      }, 20);
      clearTimeout(changeSlideTimeout);
      changeSlideTimeout = setTimeout(function() {
        _nextSlide();
      }, 6000);
    };

    $(this).on('animated', function() {
      _resetTimer();
      setTimeout(function() {
        var delay = 0;
        $($slides[currentSlide]).find('> *').each(function() {
          $(this).css({'transition-delay': delay + 'ms'});
          $(this).addClass('show');
          delay += 1200;
        });
      });
    });
    $togglePrevious.on('click', _previousSlide);
    $toggleNext.on('click', _nextSlide);
  });
};

esign.visibilityTrigger = function() {
  $('.visibility-trigger').each(function() {
    $(this).on('click', function() {
      if($(this).hasClass('checkbox-button')) {
        var contentId = $(this).attr('data-show-and-hide');
        if($(this).find('input').is(':checked')) {
          $(contentId).removeClass('hide');
        } else {
          $(contentId).addClass('hide');
        }
      } else {
        var hideId = $(this).attr('data-hide');
        var showId = $(this).attr('data-show');
        var dataScrollTop = $(this).attr('data-scroll-top');
        $(hideId).hide('fast');
        $(showId).show('fast');
        if(dataScrollTop === 'true') {
          if($('body').hasClass('modaal-noscroll')) {
            $(this).closest('.modaal-container')[0].scrollTop = 0;
          } else {
            window.scrollTo(0,0);
          }
        }
      }
    });
  });
};

esign.perfectScale = function() {
  $('.perfect-scale').each(function() {
    var $this = $(this);
    var $content = $this.find('.perfect-scale__content');
    var contentWidth = parseInt($this.attr('data-content-width'));
    var contentHeight = parseInt($this.attr('data-content-height'));
    var heightWidthRatio = contentHeight/contentWidth;

    var _onResize = function() {
      $this.css({'height': heightWidthRatio * $this.width() });
      var scale = $this.width() / contentWidth;
      if($this.width() === 0) {
        scale = 1;
      }
      $content.css({'transform': 'translate(-50%, -50%) ' + 'scale(' + scale + ')', 'width': contentWidth + 'px'});
    };

    $(window).on('resize', _onResize);
    $this.addClass('js-initialized');
    _onResize();
  });
};

esign.domainsSection = function() {
  var isAnyPartOfElementInViewport = function(el) {
    var rect = el.getBoundingClientRect();
    var windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    var windowWidth = (window.innerWidth || document.documentElement.clientWidth);
    var vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
    var horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);
    return (vertInView && horInView);
  };

  $('.domains-section').each(function() {
    var offset = $(this).offset();
    var height = $(this).height();
    var windowHeight = $(window).height();
    var $domainsSection = $(this);
    if($domainsSection) {
      console.log($domainsSection);
        $(window).on('scroll.domains', function() {
          var scrollTop = $(document).scrollTop();
          if ($domainsSection.hasClass('animate') && !isAnyPartOfElementInViewport($domainsSection[0])) {
            $domainsSection.removeClass('animate');
          } else if (scrollTop + windowHeight >= offset.top + height - height/4) {
            $domainsSection.addClass('animate');
          }
        });
        $(window).trigger('scroll.domains');

        $(window).on('resize.domains', function() {
          offset = $domainsSection.offset();
          height = $domainsSection.height();
          windowHeight = $(window).height();
        });
    }


  });
};

 esign.hubspotFormCheckCheckbox = function () {
   $('.js-check-hs-checkbox').each(function () {
     //hsForm_a124cd69-1f55-4882-901a-627573a67cd4
     var $link = $(this);
     var linkedHsForm = $('#hsForm_' +  $link.data('hs-form-id'));
     var checkboxValue = $link.data('hs-checkbox-value');
     var linkedCheckbox = linkedHsForm.find('.hs-input[value="' + checkboxValue + '"]');
     $link.on('click', function () {
       if(linkedCheckbox.prop('checked') != true) {
        linkedCheckbox.click();
       }
     })
   })
   
 }

// Initialize on docready
$(esign.init);
